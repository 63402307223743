import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";



/**
 * The Nothing
 */
class Nothing extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, withWrapper, withBorder, message } = this.props;
        
        if (withWrapper) {
            return <Wrapper className={className} withSpacing>
                <Card
                    className="nothing"
                    withBorder={withBorder}
                    withSpacing
                >
                    <h3>{NLS.get(message)}</h3>
                </Card>
            </Wrapper>;
        }

        return <Card
            className={`${className} nothing`}
            withBorder={withBorder}
            withSpacing
        >
            <h3>{NLS.get(message)}</h3>
        </Card>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className   : PropTypes.string,
        withWrapper : PropTypes.bool,
        withBorder  : PropTypes.bool,
        message     : PropTypes.string.isRequired,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withWrapper : false,
        withBorder  : false,
    }
}

export default Nothing;
